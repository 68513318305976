import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { OdataItems } from '@/shared/model/OdataItems';
import costObjective, { CostObjective } from '@/shared/model/costObjective';
import costObjectiveImportSetting, { CostObjectiveImportSetting } from '@/shared/model/costObjectiveImportSetting';
import { Organisation } from '@/shared/model/organisation';
import D4ySwitch from '@/components/d4y-switch/d4y-switch.vue';
import ImportTableView from '@/views/settings/company/account-assignment/import-table/import-table.vue';

const name = 'tax-group-view';
const logger = new Logger(name);
const costObjectiveModule = namespace('costObjective');
const costObjectiveImportSettingModule = namespace('costObjectiveImportSetting');
const organizationModule = namespace('organization');

@Component({ name: name, components: { D4ySwitch, ImportTableView } })
export default class TaxGroupView extends Vue {
  @costObjectiveImportSettingModule.Action('updateChangeClearBeforeImport')
  private updateChangeClearBeforeImport!: any;
  @costObjectiveImportSettingModule.Action('getCostObjectiveImportSetting')
  private getCostObjectiveImportSetting!: any;
  @costObjectiveImportSettingModule.Getter('getCostObjectiveImportSetting')
  private costObjectiveImportSetting!: any;
  @costObjectiveModule.Getter('getIsImporting')
  private isImporting!: boolean;
  @costObjectiveImportSettingModule.Getter('getInProgressUpdateCostObjectiveImportSetting')
  private inProgressUpdateCostObjectiveImportSetting!: CostObjectiveImportSetting;
  @costObjectiveImportSettingModule.Getter('getCostObjectiveImportSettingsIsLoading')
  private costObjectiveImportSettingsIsLoading!: CostObjectiveImportSetting;

  @costObjectiveModule.Action('getCostObjectives')
  private getCostObjectives!: any;
  @costObjectiveModule.Getter('getCostObjectives')
  private costObjectives!: OdataItems<CostObjective>;

  @organizationModule.Getter('getOrganization')
  private organization!: Organisation;

  @costObjectiveModule.Action('uploadCostObjective')
  private uploadCostObjective!: any;

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('column0'), value: 'value' },
      { text: this.$t('column1'), value: 'name' },
    ];
    return headers;
  }
}
